import {
  HomePageBannerImageDesktop,
  HomePageBannerImageMobile
} from '@components/home/HomePageBanners';
import {GoogleMerchantReviewBadgeRating} from '@components/google-badge/google-badge-rating';
import {useEffect, useState} from 'react';
import {
  HomePageFeaturedCollectionBannersDesktop,
  HomePageFeaturedCollectionBannersMobile
} from '@components/home/HomePageFeaturedCollections';

export const HomePage = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {/* Using hidden divs vs const isMobile = useMobileOrTablet() is the only way to prevent CLS issues */}
      <div className={'md:block hidden'}>
        <div id='homepage-desktop' className='mb-2'>
          <HomePageBannerImageDesktop />
          <HomePageFeaturedCollectionBannersDesktop />
        </div>
      </div>
      <div className={'block md:hidden'}>
        <div id='homepage-mobile' className='relative' suppressHydrationWarning={true}>
          <HomePageBannerImageMobile />
          <HomePageFeaturedCollectionBannersMobile />
        </div>
      </div>
      {isLoaded && <GoogleMerchantReviewBadgeRating />}
    </>
  );
};
