import Image from 'next/image';
import Link from 'next/link';
import {defaultThemeImagePaths, siteSettingsV2} from '@helpers/config-master.mjs';

export const HomePageBannerImageDesktop = () => {
  // const {MainDesktopBanner} = useHomePageBannerImages();
  const desktopHomePageMainBannerImage = defaultThemeImagePaths?.desktopHomePageMainBannerImage;
  // console.log('[HomePageBannerImageDesktop]: ', desktopHomePageMainBannerImage)
  const activeSale = siteSettingsV2.getSaleSettings();
  const link = activeSale.layoutSettings?.layout?.bannerLinks?.homepageBanner;

  return (
    <div
      id='homepage-banner-desktop'
      className='m-auto md:max-w-[1300px] overflow-hidden relative pt-2 pb-5 bg-white'>
      <Link href={link}>
        <div className='block'>
          <div className='w-full fadeIn'>
            {desktopHomePageMainBannerImage && (
              <Image
                src={desktopHomePageMainBannerImage}
                alt='SkarAudio.com Desktop Homepage Banner'
                priority
                className='align-bottom'
                width={1300}
                height={723}
                quality={85}
                loading='eager'
              />
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export const HomePageBannerImageMobile = () => {
  // const {MainMobileBanner} = useHomePageBannerImages();
  const mobileHomePageMainBannerImage = defaultThemeImagePaths?.mobileHomePageMainBannerImage;

  const activeSale = siteSettingsV2.getSaleSettings();
  const link = activeSale.layoutSettings?.layout?.bannerLinks?.homepageBanner;

  return (
    <div
      id='homepage-banner-mobile'
      className='flex flex-col w-full h-full items-center justify-between bg-white pb-0.5'>
      {mobileHomePageMainBannerImage && (
        <Link href={link} className='flex justify-center w-full aspect-[698/821]'>
          <Image
            src={mobileHomePageMainBannerImage}
            alt='SkarAudio.com Mobile Homepage Banner'
            className='align-bottom'
            width={698}
            height={821}
            loading='eager'
          />
        </Link>
      )}
    </div>
  );
};
